



































import { Component, Vue, Watch } from 'vue-property-decorator'
import API from '@/api/products'
import { CakeCategoryItem, ProductListItem, ProductListParams } from '@/shared/models/cakes'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import CakeCard from '@/components/cakes/card/Card.vue'
import Pagination from '@/components/lists/Pagination.vue'
import LoadStatusMessage from '@/components/common/LoadStatusMessage.vue'
import CakeCardSkeleton from '@/components/cakes/card/CardSkeleton.vue'

@Component({
  components: {
    WidgetTitle,
    CakeCard,
    Pagination,
    LoadStatusMessage,
    CakeCardSkeleton
  }
})
export default class Catalog extends Vue {
  list: Array<ProductListItem> = []
  isLoading = true
  hasError = false
  totalPages = 0
  statusMsgConfig = {
    error: {
      type: 'error',
      title: 'Ошибка при загрузке данных',
      subtitle: 'Попробуйте обновить страницу',
      btnText: '',
      btnLink: ''
    },
    empty: {
      type: 'empty',
      title: 'Список пуст',
      subtitle: 'В данной категории пока нет товаров',
      btnText: 'Перейти в каталог',
      btnLink: '/catalog'
    }
  }

  params: ProductListParams = {
    per_page: 24,
    page: 1,
    status: 'publish'
  }

  get cakeSlug(): string {
    return this.$route.params.slug
  }

  get cakeCategoriesMap(): Record<string, CakeCategoryItem> {
    return this.$store.getters.categoriesMap
  }

  get categoryDescription(): string {
    return this.cakeCategoriesMap[this.$route.params.slug].description || ''
  }

  @Watch('$route')
  checkIfRouteChanged(): void {
    this.getCakeCatalog()
    document.title = `${this.cakeCategoriesMap[this.$route.params.slug].name} - Кондитерская-пекарня Безе`
  }

  @Watch('cakeCategoriesMap')
  watchForCakeCategoriesMap(list: Record<string, CakeCategoryItem>): void {
    Object.entries(list).length && this.getCakeCatalog()
    document.title = `${this.cakeCategoriesMap[this.$route.params.slug].name} - Кондитерская-пекарня Безе`
  }

  created(): void {
    Object.entries(this.cakeCategoriesMap).length && this.getCakeCatalog()
  }

  getCakeCatalog(page = 1): void {
    this.params.page = page
    this.isLoading = true
    this.hasError = false
    window.scrollTo(0, 0)
    API.getCakes({ ...this.params, ...{ category: this.cakeCategoriesMap[this.cakeSlug]?.id + '' } }).then(response => {
      this.list = response?.data || []
      this.totalPages = Number(response.headers['x-wp-totalpages'])
    }).catch(() => {
      this.hasError = true
    }).finally(() => {
      this.isLoading = false
    })
  }
}
