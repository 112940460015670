



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Paginate from 'vuejs-paginate'

@Component({
  components: {
    Paginate
  }
})
export default class Pagination extends Vue {
  @Prop({ required: true }) totalPages!: number
  @Prop({ required: true }) currentPage!: number

  changePage(pageNum: number): void {
    this.$emit('changePage', pageNum)
  }
}
